<!--团队管理：人员管理-->
<template>
  <div class="">
    <div class="page-container">
      <div class="section">
        <!--搜索栏-->
        <div class="control-box1 h2Style">
          <div class="cb-left">
            <el-input
                v-model.trim="form.name"
                clearable
                :placeholder="$t('name_phone')"
                style="width: 200px;"
                @clear="form.name = undefined"
                @keyup.enter.native="handleFilter"
            />
            <el-form ref="form" class="control-item" :model="form" label-width="80px">
              <el-form-item :label="$t('account_status') + '：'" label-width="150px" class="form-item">
                <el-select v-model="form.state" clearable>
                  <el-option
                      v-for="item in state"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-form>
            <el-button
                class="control-item"
                icon="el-icon-search"
                type="primary"
                @click="handleFilter"
            >
              {{ $t('search') }}
            </el-button>
          </div>

          <div class="cb-right">
            <el-button
                type="primary"
                icon="el-icon-plus"
                @click="addTeamUserShow()"
            >
              {{ $t('add') }}
            </el-button>
          </div>
        </div>
        <!--表格-->
        <div class="table-box" style="width: 100%">
          <el-table
              v-loading="listLoading"
              :data="teamUser"
              element-loading-text="Loading"
              border
              highlight-current-row
              style="width: 100%"
          >
            <!--昵称-->
            <el-table-column :label="$t('name')" align="center" width="150">
              <template slot-scope="scope">
                {{ scope.row.cname }}
              </template>
            </el-table-column>

            <!--手机号-->
            <el-table-column :label="$t('phone_number')" align="center" width="150">
              <template slot-scope="scope">
                <span>{{ scope.row.cphone }}</span>
              </template>
            </el-table-column>
            <!--角色-->
            <el-table-column :label="$t('account_status')" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.state === 0">{{ $t('enable') }}</span>
                <span v-else>{{ $t('disable') }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" :label="$t('adding_time')" align="center" width="170">
              <template slot-scope="scope">
                <span>{{ scope.row.dcreateDate }}</span>
              </template>
            </el-table-column>
            <!--注册时间-->
            <el-table-column prop="created_at" :label="$t('last_login_time')" align="center" width="170">
              <template slot-scope="scope">
                <span>{{ scope.row.lastDate }}</span>
              </template>
            </el-table-column>
            <!--操作-->
            <!--        v-if="managePermission"-->
            <el-table-column :label="$t('caozuo')" align="center" width="280">
              <template slot-scope="scope">
                <el-button
                    type="primary"
                    size="mini"
                    @click="handleEditPassword(scope.row)"
                >
                  {{ $t('edit_password') }}
                </el-button>
                <el-button
                    type="primary"
                    size="mini"
                    @click="updateTeamUser(scope.row)"
                >
                  {{ $t('device_edit') }}
                </el-button>

                <el-button
                    v-if="scope.row.state === 1"
                    size="mini"
                    type="primary"
                    @click="handleStart(scope.row)"
                >
                  {{ $t('enable') }}
                </el-button>
                <el-button
                    v-else
                    size="mini"
                    type="primary"
                    @click="handleDisable(scope.row)"
                >
                  {{ $t('disable') }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--分页-->
        <div class="pagination-box">
          <el-pagination
              :page-sizes="[10, 20, 30, 40]"
              :page-size="form.count"
              :total="totalCount"
              :current-page="form.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>

      <!--弹出框：添加账号-->
      <el-dialog
          :visible.sync="TeamUserShow"
          :title="dialog.title[dialog.status]"
          :close-on-click-modal="false"
          :modal-append-to-body="false"
          :append-to-body="false"
      >
        <el-form ref="inviteForm" label-width="120px" :model="formInfo" :rules="rules.inviteRules">
          <el-form-item :label="$t('name') + ':'" prop="cname">
            <el-input
                v-model="formInfo.cname"
                show-word-limit
                maxlength="30"
                clearable
                :placeholder="$t('please_enter_name')"
            />
          </el-form-item>
          <el-form-item :label="$t('phone_number') + ':'" prop="cphone">
            <el-input
                v-model="formInfo.cphone"
                show-word-limit
                maxlength="11"
                clearable
                :placeholder="$t('please_enter_phone_number')"
                @input="changeValue"
            />
            <!--     size="medium"       -->
          </el-form-item>
          <el-form-item v-if="dialog.status !== 'edit'" :label="$t('password') + ':'" prop="password">
            <el-input
                v-model="formInfo.password"
                type="password"
                clearable
                :placeholder="$t('please_enter_password')"
                @input="change($event)"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="TeamUserShow = false">{{ $t('cancel') }}</el-button>
          <el-button type="primary" :loading="addUserBtnLoading" @click="dialog.status === 'add' ? addTeamUser() : editConfirm()">
            {{ $t('confirm') }}
          </el-button>
        </div>
      </el-dialog>

      <!--弹出框：编辑密码-->
      <el-dialog
          :visible.sync="teamUserEditShow"
          :title="$t('edit_password')"
          :close-on-click-modal="false"
          :modal-append-to-body="false"
          :append-to-body="true"
      >
        <div class="mr-70">
          <el-form ref="teamForm" label-width="140px" :model="editPassword" :rules="rules.inviteRules">
            <el-form-item :label="$t('new_password') + ':'" prop="password">
              <el-input
                  v-model="editPassword.password"
                  type="password"
                  clearable
                  :placeholder="$t('enter_new_password')"
              />
            </el-form-item>
            <el-form-item :label="$t('repeat_password') + ':'" prop="password2">
              <el-input
                  v-model="editPassword.password2"
                  type="password"
                  clearable
                  :placeholder="$t('enter_password_again')"
              />
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="teamUserEditShow = false">{{ $t('cancel') }}</el-button>
          <el-button type="primary" :loading="editDetailBtnLoading" @click="editTeamUserRoles">
            {{ $t('confirm') }}
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import API from '@/api';
import valid from '@/utils/validator';
import API from '@/api';

export default {
  name: 'PersonManagement',
  data() {
    const setPassword = (rule, value, callback) => {
      if (value.length > 30 || value.length < 6) {
        callback(new Error(this.$t('length6_characters')));
      }
      callback();
    };
    const validateForget2 = (rule, value, callback) => {
      if (value !== this.editPassword.password) {
        callback(new Error(this.$t('twice_password')));
      } else {
        callback();
      }
    };
    return {
      teamUser: [],
      TeamUserShow: false,
      teamUserEditShow: false,
      addUserBtnLoading: false,
      editDetailBtnLoading: false,
      formInfo: {
        cname: '',
        cphone: '',
        password: '',
        id: '',
      },
      state: [
        { value: '', label: this.$t('all') },
        { value: 0, label: this.$t('enable') },
        { value: 1, label: this.$t('disable') },
      ],
      stateId: [
        { value: 0, label: this.$t('enable') },
        { value: 1, label: this.$t('disable') },
      ],
      listLoading: false,
      // 查询
      form: {
        name: '',
        state: '',
        page: 1,
        count: 10,
      },
      //
      editPassword: {
        password: '',
        password2: '',
      },
      dialog: {
        status: '',
        title: {
          add: this.$t('add_repairman'),
          edit: this.$t('edit_repairman'),
        },
      },
      account: this.$t('add_repairman'),
      rules: {
        inviteRules: {
          cphone: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                valid.checkNull(rule, value, callback, this.$t('please_enter_phone_number'), false);
                valid.checkByRegex(rule, value, callback, valid.REG_PHONE, this.$t('invalid_phone'));
              },
            },
          ],
          cname: [
            { required: true, message: this.$t('please_enter_name'), trigger: 'change' },
            {
              min: 2, max: 30, message: this.$t('length30_characters'), trigger: 'blur',
            },
          ],
          password: [
            { required: true, message: this.$t('please_enter_password'), trigger: 'blur' },
            {
              validator: setPassword,
              trigger: 'change',
            },
          ],
          password2: [
            { required: true, message: this.$t('again_enter_password'), trigger: 'blur' },
            { validator: validateForget2, trigger: 'blur' },
          ],
        },
      },
      totalCount: 0,
      buttons: '',
    };
  },
  mounted() {
    this.getTeamUser();
  },
  methods: {
    changeValue(value) {
      this.formInfo.phone = value.replace(/[^\d]/g, '');
    },
    getTeamUser() {
      API.getMaintenanceDate(this.form).then((response) => {
        console.log(response);
        this.teamUser = response.message.data.list;
        this.totalCount = response.message.data.totalCount;
      });
    },
    handleFilter() {
      this.form.count = 10;
      this.form.page = 1;
      this.getTeamUser();
    },
    // 添加弹窗
    addTeamUserShow() {
      this.dialog.status = 'add';
      this.formInfo.cname = '';
      this.formInfo.cphone = '';
      this.formInfo.password = '';
      this.TeamUserShow = true;
      this.$nextTick(() => {
        this.$refs.inviteForm.clearValidate();
      });
    },
    // 添加弹窗确定
    addTeamUser() {
      this.$refs.inviteForm.validate((v) => {
        if (v) {
          console.log(2);
          this.addUserBtnLoading = true;
          const params = JSON.parse(JSON.stringify(this.formInfo));
          console.log(params);
          API.addMaintenanceDate(params).then((res) => {
            console.log(res);
            if (res.message.success === true) {
              this.TeamUserShow = false;
              this.addUserBtnLoading = false;
              this.$message.success(this.$t('add_successful'));
              this.formInfo.cname = '';
              this.formInfo.cphone = '';
              this.formInfo.password = '';
              this.getTeamUser();
            } else {
              this.$message.error(res.message.message);
            }
          }).catch(() => {
            this.addUserBtnLoading = false;
          }).finally(() => {
            this.addUserBtnLoading = false;
          });
        } else {
          return false;
        }
        return v;
      });
    },
    // 编辑按钮
    updateTeamUser(row) {
      this.dialog.status = 'edit';
      this.formInfo = JSON.parse(JSON.stringify(row));
      this.TeamUserShow = true;
      this.$nextTick(() => {
        this.$refs.inviteForm.clearValidate();
      });
    },
    // 编辑弹窗确定
    editConfirm() {
      this.$refs.inviteForm.validate((v) => {
        if (v) {
          console.log(1);
          this.addUserBtnLoading = true;
          const params = JSON.parse(JSON.stringify(this.formInfo));
          console.log(params);
          API.editMaintenancePassword(params, params.id).then((res) => {
            console.log(res);
            if (res.message.success === true) {
              this.TeamUserShow = false;
              this.addUserBtnLoading = false;
              this.$message.success(this.$t('edit_successful'));
              this.formInfo.cname = '';
              this.formInfo.cphone = '';
              this.formInfo.password = '';
              this.getTeamUser();
            } else {
              this.$message.error(res.message.message);
            }
          }).catch(() => {
            this.addUserBtnLoading = false;
          }).finally(() => {
            this.addUserBtnLoading = false;
          });
        } else {
          return false;
        }
        return v;
      });
    },
    // 编辑密码弹窗
    handleEditPassword(row) {
      this.editPassword.password = '';
      this.editPassword.password2 = '';
      console.log(row);
      // this.formInfo = JSON.parse(JSON.stringify(row));
      this.formInfo.id = row.id
      this.teamUserEditShow = true;
      this.$nextTick(() => {
        this.$refs.teamForm.clearValidate();
      });
    },
    // 编辑密码弹窗确定
    editTeamUserRoles() {
      this.$refs.teamForm.validate((v) => {
        if (v) {
          this.editDetailBtnLoading = true;
          console.log(this.editPassword.password);
          API.editMaintenanceDate(this.editPassword, this.formInfo.id).then((res) => {
            console.log(res);
            if (res.message.success === true) {
              this.teamUserEditShow = false;
              this.editDetailBtnLoading = false;
              this.$message.success(this.$t('edit_successful'));
              this.editPassword.password = '';
              this.editPassword.password2 = '';
              this.getTeamUser();
            } else {
              this.$message.error(res.message.message);
            }
          }).catch(() => {
            this.editDetailBtnLoading = false;
          }).finally(() => {
            this.editDetailBtnLoading = false;
          });
        } else {
          return false;
        }
        return v;
      });
    },
    // 启用
    handleStart(row) {
      this.$confirm(this.$t('enable_current_repairman_account'), this.$t('enable_account'), {
        closeOnClickModal: false,
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
          .then(() => {
            API.editMaintenanceState({ state: 0 }, row.id).then(() => {
              this.$message({
                message: this.$t('success_enabled'),
                showClose: true,
                type: 'success',
              });
              this.getTeamUser();
            });
          });
    },
    // 停用
    handleDisable(row) {
      this.$confirm(this.$t('disable_current_repairman_account'), this.$t('disable_account'), {
        closeOnClickModal: false,
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      })
          .then(() => {
            API.editMaintenanceState({ state: 1 }, row.id).then(() => {
              this.$message({
                message: this.$t('Deactivate_success'),
                showClose: true,
                type: 'success',
              });
              this.getTeamUser();
            });
          });
    },
    handleSizeChange(val) {
      this.form.count = val;
      this.form.page = 1;
      this.getTeamUser();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getTeamUser();
    },
    change() {
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
//.page-container{
//  padding: 40px;
//}
//.section{
//  padding: 30px 40px;
//}
.h2Style {
  margin-bottom: 20px;
  //padding-bottom: 20px;
  border-bottom: 1px solid #dcdfe6;
  font-size: 20px;
}
.PersonManagement {
  width: 100%;
}
.control-box1 {
  display: flex;
  justify-content: space-between;
}
.cb-left {
  display: -webkit-box;
}
.control-item {
  margin-left: 15px;
}
::v-deep .form-item{
  display: inline-block;
}
</style>
